<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="station_actions" aria-labelledby="station_actionsLabel">
        <div class="offcanvas-header">
            <h5 id="station_actionsLabel">Station Details</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body pt-0">

            <div class="table-card mt-2" v-if="Object.keys(station_info).length > 0">
                <form @submit.prevent="saveStation()" v-if="delete_status === false">
                    <table class="table mb-0">
                        <tbody>
                        <tr class="align-middle">
                            <td class="fw-medium" style="min-width: 120px">Name</td>
                            <td>
                                <input class="form-control py-0 border-0" v-model="station_info.name">
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-medium">Code</td>
                            <td>
                                <input class="form-control py-0 border-0" v-model="station_info.code">
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-medium">Railway name</td>
                            <td>
                                <input class="form-control py-0 border-0" v-model="station_info.railway_name">
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-medium">
                                <b-button class="btn btn-light" type="button"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#station_actions"
                                          aria-controls="station_actions"
                                >Close
                                </b-button>
                            </td>
                            <td class="text-end">

                                <b-button v-if="!is_updating" type="submit"
                                          :variant="update_status === false ? 'success' : update_status === 200 ? 'success' : 'warning'">
                                    {{
                                    update_status === false ? 'Save' : update_status === 200 ? 'Saved' : 'Try Again'
                                    }}
                                </b-button>
                                <button v-else-if="is_updating" type="button" class="btn btn-success btn-load">
                                    <span class="d-flex align-items-center">
                                        <span class="spinner-grow flex-shrink-0" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </span>
                                        <span class="flex-grow-1 ms-2">
                                            Saving...
                                        </span>
                                    </span>
                                </button>


                                <b-button @click="deleteStation()"
                                          class="ms-2"
                                          :variant="delete_status === false ? 'danger' : delete_status === 204 ? 'danger' : 'warning'">
                                    {{
                                    delete_status === false ? 'Delete' : delete_status === 204 ? 'Deleted' : 'Try Again'
                                    }}
                                </b-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
                <div v-else class="ps-3">
                    <lottie :options="no_station_animation" :width="200" :height="200"/>
                    <h5 class="text-center">Station No Longer Exists</h5>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import Lottie from "@/components/widgets/lottie.vue";
import spxnqpau from "@/components/widgets/spxnqpau.json";

export default {
    name: "actions_offcanvas",
    components: {lottie: Lottie,},
    props: {
        station: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            update_status: false,
            delete_status: false,
            is_updating: false,
            is_deleting: false,
            no_station_animation: {animationData: spxnqpau},
        }
    },

    computed: {
        station_info() {
            return this.station
        }
    },
    methods: {
        async saveStation() {
            this.is_updating = true
            try {
                let response = await axios.put('/core/stations/' + this.station_info.id + '/update/', {
                    name: this.station_info.name,
                    code: this.station_info.code,
                    railway_name: this.station_info.railway_name
                })
                this.update_status = response.status
            } catch {
                this.update_status = 500
            }
            this.is_updating = false
        },
        async deleteStation() {
            this.is_deleting = true
            try {
                let response = await axios.delete('/core/stations/' + this.station_info.id + '/delete/')
                this.delete_status = response.status
            } catch {
                this.delete_status = 500
            }
            this.is_deleting = false
        }
    },
    watch: {
        station() {
            this.update_status = false
            this.delete_status = false
            this.is_deleting = false
            this.is_updating = false
        }
    }
}
</script>

<style scoped>

</style>