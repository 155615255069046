<template>
  <PageHeader :title="title" :items="items"/>

  <div class="mb-4">
    <Table :name="table.name" :url="table.url" :headers="table.headers" :searchable="true" :selectable="true">

      <template v-slot:top-right>
        <b-button variant="success" size="md" data-bs-toggle="modal"
                  data-bs-target="#generalStationCreate">
          <i class="ri-add-fill align-bottom px-1"></i>
          Create Station
        </b-button>
      </template>

      <template v-slot:actions="props">

        <b-button-group class="mt-4 mt-md-0" role="group" aria-label="Basic example">
          <b-button variant="light" size="md" data-bs-toggle="offcanvas"
                    data-bs-target="#station_actions"
                    aria-controls="station_actions"
                    @click="current_station = props.row">
            <i class="ri-pencil-fill align-bottom px-0"></i>
          </b-button>
        </b-button-group>

      </template>
    </Table>
  </div>
  <ActionsOffcanvas :station="current_station"/>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import Table from "@/components/tables/table.vue";
import ActionsOffcanvas from "./StationActions.vue";

export default {
  name: "index",
  components: {
    Table,
    PageHeader,
    ActionsOffcanvas
  },
  data() {
    return {
      title: "Stations",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Stations",
          active: true,
        },
      ],
      table: {
        name: 'Stations Table',
        url: '/core/stations/',
        headers: [
          {
            field: 'id',
            label: 'ID',
            align: 'center',
            visible: false
          },
          {
            field: 'name',
            label: 'NAME',
            align: 'center',
          },
          {
            field: 'code',
            label: 'CODE',
            align: 'center',
          },
          {
            field: 'railway_name',
            label: 'RAILWAY NAME',
            align: 'center',
          },
          {
            field: 'actions',
            label: 'ACTIONS',
            align: 'center',
          },
        ]
      },
      current_station: {}
    }
  }
}
</script>

<style scoped>

</style>